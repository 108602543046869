<template>
    <div class="container mt-5 pt-5" v-if="userInfo.completed_registration === 'true'">
        In caso di password smarrita utilizzare il bot su Telegram <a
            href="https://telegram.me/AltivoliBot">@AltivoliBot</a> per recuperarla. In caso di problemi di
        qulasiasi tipo non esitare a contattare il direttivo o il creatore del bot e manutentore del sito <a
            href="https://telegram.me/flyingmp">@flyingmp</a>
    </div>
    <div class="container mt-5 pt-5" v-if="userInfo.completed_registration === 'true'">
        <h2>Pannello di Controllo</h2>
        <div class="tabs">
            <div class="tab" :class="{ active: activeTab === 'personal' }" @click="activeTab = 'personal'">
                Informazioni Personali
            </div>
            <div class="tab" :class="{ active: activeTab === 'password' }" @click="activeTab = 'password'">
                Reset Password
            </div>
            <div class="tab" :class="{ active: activeTab === 'agreements' }" @click="activeTab = 'agreements'">
                Accordi Accettati
            </div>
        </div>
        <!------------------------------------------------------------------------------------------------------------------>
        <div class="tab-content" v-if="activeTab === 'personal'">
            <label class="mb-3">
                <strong>Ora puoi entrare nel gruppo ufficiale della Flyzone!</strong> Inserisci il comando
                <i>/gruppo</i> sul bot
                Telegram <a href="https://telegram.me/AltivoliBot">@AltivoliBot</a>. <br>
                Le informazioni in <span style="color: red;">rosso</span> non sono direttamente modificabili. In
                particolare l'ID Telegram non potrà in nessun modo essere modificato. <br>
                Per correggere le altre infromazioni è necessario contattare il direttivo o <a
                    href="https://telegram.me/flyingmp">@flyingmp</a> su Telegram.</label><br>
            Le informazioni in <span style="color: green;">verde</span> possono essere modificate nel modulo
            sottostante.<br><br>
            <form @submit.prevent="updateInfo">
                <div class="form-group col col-md-4 col-lg-3 mb-3">
                    <label style="color: red;"><strong>ID Telegram (Username)</strong></label>
                    <input class="form-control" type="text" :value="userInfo.id" disabled />

                    <label v-if="userInfo.admin === 'true'" style="color: green;">Sei un <strong>ADMIN</strong> del
                        sito!
                        (<em>puoi creare, eliminare e modificare gli eventi nell'apposita sezione</em>)</label>
                </div>

                <div class="form-group col col-md-4 col-lg-3 mb-3">
                    <label style="color: red;"><strong>Nome</strong></label>
                    <input class="form-control" type="text" :value="userInfo.name" disabled />
                </div>
                <div class="form-group col col-md-4 col-lg-3 mb-3">
                    <label style="color: red;"><strong>Cognome</strong></label>
                    <input class="form-control" type="text" :value="userInfo.surname" disabled />
                </div>

                <div class="form-group col col-md-4 col-lg-3 mb-3 ">
                    <label style="color: red;"><strong>Data di nascita</strong></label>
                    <input class="form-control" type="date" :value="userInfo.birthdate" disabled />
                </div>

                <div class="form-group col col-md-4 col-lg-3 mb-3">
                    <label style="color: red;"><strong>Seriale Brevetto di Volo</strong></label>
                    <input class="form-control" type="text" :value="userInfo.license_number" disabled />
                </div>

                <div class="form-group col col-md-4 col-lg-3 mb-3">
                    <label style="color: green;"><strong>Numero di telefono</strong></label>
                    <input class="form-control" type="text"
                        :value="'+' + userInfo.phone_prefix + ' ' + userInfo.phone_number" disabled />
                </div>

                <div class="form-group col col-md-4 col-lg-5 mb-3">
                    <label style="color: green;"><strong>Assicurazione</strong></label>
                    <input class="form-control" type="text"
                        :value="`${userInfo.insurance_name}, n° ${userInfo.insurance_number}`" disabled />
                </div>

                <hr class="mb-3">
                <label class="mb-3">Le informazioni sottostanti possono essere modificate. Qualunque modifica che non
                    risulti
                    conforme con dati veritieri può essere punita legalmente. </label>

                <div class="mb-3 col col-md-6 col-lg-6">
                    <div class="input-group mb-2">
                        <span class="input-group-text"><strong>Prefisso</strong></span>
                        <input minlength="2" type="text" class="form-control " v-model="modifyInfo.phone_prefix">

                    </div>
                    <div class="input-group">
                        <span class="input-group-text"><strong>Numero di Telefono</strong></span>
                        <input minlength="6" type="text" class="form-control" v-model="modifyInfo.phone_number">
                    </div>
                    <div class="form-text" id="CellphoneWarning">Inserire il nuovo numero di telefono specificando il
                        prefisso</div>
                </div>

                <div class="mb-3 col col-md-6 col-lg-6">
                    <div class="input-group">
                        <span class="input-group-text" id="insuranceName"><strong>Nome Assicurazione</strong></span>
                        <input type="text" minlength="3" class="form-control" v-model="modifyInfo.insurance_name">
                    </div>
                    <div class="form-text" id="insuranceNameWarning">Inserire il nome del nuovo assicuratore (e.g.
                        Benacquista)</div>
                </div>

                <div class="mb-3 col col-md-6 col-lg-6">
                    <div class="input-group ">
                        <span class="input-group-text" id="insuranceCode"><strong>Numero Assicurazione</strong></span>
                        <input type="text" minlength="3" class="form-control" v-model="modifyInfo.insurance_number">
                    </div>
                    <div class="form-text" id="insuranceCodeWarning">Inserire il numero seriale assicurativo (e.g
                        IAV...)</div>
                </div>
                <!-- Add other form fields as needed -->
                <p id="updateWarning"></p>
                <button class="btn btn-primary" type="submit" :disabled=" (modifyInfo.insurance_name === undefined || modifyInfo.insurance_name.trim().length==0)
                    && ( modifyInfo.insurance_number === undefined || modifyInfo.insurance_number.trim().length==0)
                    && ( modifyInfo.phone_prefix === undefined || modifyInfo.phone_prefix.trim().length==0)
                    && ( modifyInfo.phone_number === undefined || modifyInfo.phone_number.trim().length==0)">
                    Aggiorna Informazioni</button>

            </form>
        </div>
        <!------------------------------------------------------------------------------------------------------------------>
        <div class="tab-content" v-if="activeTab === 'password'">
            <label class="mb-3">Nel caso in cui si voglia modificare la propria password basta semplicemente inserirne
                una nuova negli appositi campi e cliccare sul tasto 'Reset Password'. </label>
            <form @submit.prevent="resetPassword">
                <div class="form-group col-10 col-lg-6">
                    <label for="new-password"><strong>Nuova Password</strong></label>
                    <input minlength="5" class="form-control" type="password" id="new-password" v-model="newPassword"
                        required />

                    <label for="new-password2"><strong>Conferma Nuova Password</strong></label>
                    <input minlength="5" class="form-control" type="password" id="new-password2" v-model="newPassword2"
                        required />
                </div>
                <p class="mt-3" id="pwdUpdateWarning"></p>
                <button id="changePwdButton" class="btn btn-primary mt-3" type="submit"
                    :disabled="newPassword == '' && newPassword2 == ''">Reset Password</button>
            </form>
        </div>
        <!------------------------------------------------------------------------------------------------------------------>
        <div class="tab-content" v-if="activeTab === 'agreements'">

            <label class="mb-2">Questi sono gli accordi accettati, se in un secondo momento si voglia recedere dagli
                accordi
                è necessario contattare il direttivo per rimuovere la vostra iscrizione.
                Tramite questa interfaccia è solamente possibile visualizzare gli accordi accettati.</label>

            <div class="form-group">
                <input type="checkbox" id="military-agreement" v-model="userInfo.military_agreement" disabled />
                <label for="military-agreement">Accordo con il 60° Stormo (visionabile
                    <a href="./assets/RulesFlyzone.pdf" download="RulesFlyzone.pdf">qui</a>)</label>
            </div>
            <div class="form-group">
                <input type="checkbox" id="responsibility-agreement" v-model="userInfo.responsibility_agreement"
                    disabled />
                <label for="responsibility-agreement">Dichiarazione di Responsabilità</label>
            </div>
            <div class="form-group">
                <input type="checkbox" id="privacy-agreement" v-model="userInfo.privacy_agreement" disabled />
                <label for="privacy-agreement">Condizioni sulla Privacy</label>
            </div>
        </div>
        <!------------------------------------------------------------------------------------------------------------------>
    </div>

    <div class="container-fluid mt-5 pt-5 d-flex justify-content-center align-items-center special" v-else>
        <div class="row">
            <h2 class="mt-2 text-center">Completa la registrazione</h2>
            <p class="text-center"> Per poter essere autorizzato a volare all'interno della Flyzone è <u>necessario
                    completare la registrazione</u></p>
        </div>






        <form @submit.prevent="complete_reg">
            <div class="mb-3 col col-lg-6">
                <div class="input-group">
                    <span class="input-group-text font-weight-bold">Telegram ID</span>
                    <input type="text" class="form-control" id="nameValue" :placeholder="userInfo.id" disabled>
                </div>
                <div class="form-text">Questo campo non è modificabile, indica solo il tuo ID di Telegram
                </div>
            </div>

            <div class="mb-3 col col-lg-6">
                <div class="input-group">
                    <span class="input-group-text font-weight-bold" id="name">Nome</span>
                    <input type="text" class="form-control" id="nameValue" v-model="userInfo.name" required>
                </div>
            </div>

            <div class="mb-3 col col-lg-6">
                <div class="input-group ">
                    <span class="input-group-text font-weight-bold" id="surname">Cognome</span>
                    <input type="text" class="form-control" id="surnameValue" v-model="userInfo.surname" required>
                </div>
            </div>

            <div class="mb-3 col col-lg-6">
                <label for="basic-url" class="form-label"><strong>Nuova Password</strong></label>
                <div class="input-group mb-2">
                    <span class="input-group-text font-weight-bold" id="pwd">Password</span>
                    <input type="password" class="form-control" id="pwdValue" v-model="userInfo.password" required>
                </div>
                <div class="input-group">
                    <span class="input-group-text font-weight-bold" id="pwd2">Conferma Password</span>
                    <input type="password" class="form-control" id="pwd2Value" v-model="confirmed_password" required>
                </div>
                <div class="form-text">Per questioni di sicurezza è necessario creare una nuova password
                </div>
            </div>


            <div class="col col-lg-6">
                <label for="birthdate"><strong>Numero di Telefono</strong></label>
                <div class="input-group">
                    <span class="input-group-text">Prefisso</span>
                    <input type="text" class="form-control col-2 col-md-1 col-lg-2 font-weight-bold"
                        v-model="userInfo.phone_prefix" required>
                </div>
            </div>

            <div class="col col-lg-6 mb-3">
                <div class="input-group mt-2">
                    <span class="input-group-text">Numero</span>
                    <input type="text" class="form-control col-5 col-md-3 col-lg-3 font-weight-bold"
                        v-model="userInfo.phone_number" required>
                </div>
            </div>


            <div class="mb-3 col col-lg-6">
                <div class="form-group">
                    <label for="birthdate"><strong>Data di nascita</strong></label>
                    <input type="date" class="form-control" id="birthdate" name="birthdate" v-model="userInfo.birthdate"
                        required>
                </div>
            </div>

            <div class="mb-3 col col-lg-6">
                <div class="input-group ">
                    <span class="input-group-text font-weight-bold" id="license">Seriale Attestato di
                        Volo (brevetto)</span>
                    <input type="number" minlength="5" maxlength="10" class="form-control" id="licenseValue"
                        v-model="userInfo.license_number" required>
                </div>
                <div class="input-group mt-2">
                    <span class="input-group-text font-weight-bold " id="license">Conferma Attestato</span>
                    <input type="number" minlength="5" maxlength="10" class="form-control" id="licenseValue2"
                        v-model="confirmed_serial" required>
                </div>
                <div class="form-text" id="licenseWarning">Inserire il vostro numero di attestato di volo</div>
            </div>

            <div class="mb-3 col col-lg-6">
                <div class="input-group ">
                    <span class="input-group-text font-weight-bold" id="insuranceName">Nome
                        Assicurazione</span>
                    <input type="text" minlength="2" class="form-control" id="insuranceNameValue"
                        v-model="userInfo.insurance_name" required>
                </div>
                <div class="form-text" id="insuranceNameWarning">Inserire il nome dell'assicuratore (e.g.
                    Benacquista)</div>
            </div>

            <div class="mb-3 col col-lg-6">
                <div class="input-group ">
                    <span class="input-group-text font-weight-bold" id="insuranceCode">N°</span>
                    <input type="text" minlength="3" class="form-control" id="insuranceCodeValue"
                        v-model="userInfo.insurance_number" required>
                </div>
                <div class="form-text" id="insuranceCodeWarning">Inserire il numero seriale assicurativo
                    (e.g
                    IAV...)</div>
            </div>

            <div class="form-check mb-2">
                <input class="form-check-input" type="checkbox" value="" id="responsibilityCheck" checked required
                    v-model="userInfo.responsibility_agreement">
                <label class="form-check-label" for="responsibilityCheck">
                    Accetto l'assunzione di responsabilità
                </label>
                <div class="form-text col col-lg-6" id="responsibilityWarning" style="color: red;">
                    Con la presente dichiarazione, l'utente conferma che le informazioni fornite sono
                    veritiere
                    e
                    si assume la piena responsabilità per qualsiasi dichiarazione falsa. L'utente comprende
                    che
                    dichiarazioni
                    mendaci possono comportare gravi rischi e conseguenze legali.
                </div>
            </div>

            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="privacyCheck" checked required
                    v-model="userInfo.privacy_agreement">
                <label class="form-check-label" for="privacyCheck">
                    Accetto le condizioni della privacy
                </label>
                <div class="form-text col col-lg-6" id="privacyWarning" style="color: red;">
                    I dati forniti saranno conservati dall'Associazione Sportiva per la gestione
                    dell'accesso
                    alla zona di volo militare, in conformità con il Regolamento Generale sulla Protezione
                    dei
                    Dati
                    (GDPR).
                    L'Associazione si impegna a trattare i dati in modo sicuro e riservato. Tuttavia,
                    l'utente
                    accetta che,
                    in caso di illeciti, l'Associazione ha l'obbligo di segnalare tali azioni alle autorità
                    competenti.
                </div>
            </div>
            <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" value="" id="militaryCheck" checked required
                    v-model="userInfo.military_agreement">
                <label class="form-check-label" for="militaryCheck">
                    Accetto le condizioni della lettera d'accordi del 60° stormo
                </label>
                <div class="form-text col col-lg-6" id="privacyWarning" style="color: red;">
                    L'accordo è visionabile
                    <a href="./assets/RulesFlyzone.pdf" download="RulesFlyzone.pdf">qui</a>
                </div>
            </div>

            <button id="registrationButton" class="btn btn-primary mb-3" style="background-color: green;"
                type="submit">Registrati</button>
        </form>

    </div>


</template>

<script>
export default {
    props: {
        telegram_id: Number,
    },
    data() {
        return {
            activeTab: 'personal',
            confirmed_password: '',
            confirmed_serial: '',
            userInfo: {},
            modifyInfo: {},
            newPassword: '',
            newPassword2: '',

        };
    },

    async mounted() {
        if (this.telegram_id === undefined) {
            this.$emit('emitPageChange', { page: 'login' });
            return;
        }
        this.userInfo.id = this.telegram_id;

        if (localStorage.getItem('user_data')) {
            await this.$axios.get(`/user_info/${this.telegram_id}`)
                .then((response) => {
                    console.log(response);
                    this.userInfo = response.data.data;
                    localStorage.setItem('user_data', JSON.stringify(this.userInfo));
                })
                .catch((error) => {
                    document.getElementById('registrationButton').disabled = true;
                    if (error.code == "ERR_NETWORK") {
                        alert("Errore di connessione, riprovare più tardi");
                        return;
                    }
                    if (error.response.status !== 500) {
                        alert("E' necessario effettuare il login per accedere a questa pagina");
                        this.$emit('emitLogout');
                        return;
                    }
                    alert("Si è verificato un errore, riprovare più tardi");
                    return;
                });
        }

    },

    methods: {
        updateInfo() {
            document.getElementById('updateWarning').style.color = "red";
            if (Object.keys(this.modifyInfo).length === 0  ) { //|| Object.keys(this.modifyInfo).trim().length === 0
                document.getElementById('updateWarning').innerHTML = "Per aggiornare le informazioni è necessario modificare almeno un elemento"
                return
            }

            if ((Object.hasOwn(this.modifyInfo, 'phone_prefix') && Object.hasOwn(this.modifyInfo, 'phone_number')) ||
                (Object.hasOwn(this.modifyInfo, 'insurance_name') && Object.hasOwn(this.modifyInfo, 'insurance_number'))) {
                //console.log("Sent to backend for modification");
            } else {
                document.getElementById('updateWarning').innerHTML = "Hai modificato parzialmente i campi di telefono e assicurazione, per favore correggi l'errore"
                return
            }


            this.$axios.put(`/user_info/${this.telegram_id}`, { data: this.modifyInfo })
                .then(() => {
                    document.getElementById('updateWarning').style.color = "green";
                    document.getElementById('updateWarning').innerHTML = "Dati modificati correttamente";
                    if (Object.hasOwn(this.modifyInfo, 'phone_prefix') && Object.hasOwn(this.modifyInfo, 'phone_number')) {
                        this.userInfo.phone_prefix = this.modifyInfo.phone_prefix;
                        this.userInfo.phone_number = this.modifyInfo.phone_number;
                    }
                    if (Object.hasOwn(this.modifyInfo, 'insurance_name') && Object.hasOwn(this.modifyInfo, 'insurance_number')) {
                        this.userInfo.insurance_name = this.modifyInfo.insurance_name;
                        this.userInfo.insurance_number = this.modifyInfo.insurance_number;
                    }
                    localStorage.setItem('user_data', JSON.stringify(this.userInfo));
                    this.modifyInfo = {};
                })
                .catch(() => {
                    document.getElementById('updateWarning').style.color = "red";
                    document.getElementById('updateWarning').innerHTML = "Si è verificato un errore, riprovare più tardi"
                    document.getElementById('registrationButton').disabled = true;
                    return;
                });
        },

        resetPassword() {
            document.getElementById('pwdUpdateWarning').style.color = "red";
            if (this.newPassword !== this.newPassword2) {
                document.getElementById('pwdUpdateWarning').innerHTML = "Le password non corrispondono"
                return
            }

            this.$axios.put('/modify_password', { id: this.telegram_id, password: this.newPassword })
                .then(() => {
                    document.getElementById('pwdUpdateWarning').style.color = "green";
                    document.getElementById('pwdUpdateWarning').innerHTML = "Password modificata correttamente";
                    this.newPassword = ''; this.newPassword2 = '';
                })
                .catch(() => {
                    document.getElementById('pwdUpdateWarning').style.color = "red";
                    document.getElementById('pwdUpdateWarning').innerHTML = "Si è verificato un errore, riprovare più tardi"
                    document.getElementById('changePwdButton').disabled = true;
                    return;
                });
        },

        complete_reg() {
            if (this.userInfo.password !== this.confirmed_password) {
                alert("Le password non corrispondono")
                return;
            }

            if (this.userInfo.license_number !== this.confirmed_serial) {
                alert("I numeri di brevetto non corrispondono")
                return;
            }

            if (this.userInfo.birthdate.length > 10) {
                alert("Data di nascita non valida!")
                return;
            }

            this.$axios.post('/complete_registration', this.userInfo)
                .then((response) => {
                    console.log(response.data);
                    localStorage.setItem('user_data', JSON.stringify(this.userInfo));
                    this.userInfo.completed_registration = 'true';
                    this.confirmed_password = '';
                })
                .catch(() => {
                    alert("Si è verificato un errore, riprovare più tardi (ricaricare la pagina)")
                    document.getElementById('registrationButton').disabled = true;
                    return;
                });

        }
    }
};
</script>

<style scoped>
@media screen and (max-width: 576px) {
    .special {
        flex-direction: column;
    }
}

.tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
}

.tab.active {
    font-weight: bold;
    border-bottom: 2px solid #007bff;
}

.tab-content {
    padding: 20px;
}
</style>