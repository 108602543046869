<template>
  <!--- -->
  <div class="container-fluid m-0 mt-5 pt-5">
    
    <div class="row mt-2">
      <div class="col text-center">
        <h1>Contatti del Club</h1>
      </div>
    </div>

    <hr>

    <div class="row text-center">
        <span><strong>Non riusciste a trovare le informazioni che state cercando? Vorreste venire a volare a Tivoli?</strong><br>Non esitate a contattare i membri 
          del club direttamente o tramite il gruppo Telegram Altivoli! </span>
    </div>

    <div class="row mt-4 text-center">
      <a href="https://t.me/+vhcnGDJIPNI5ZWY0"><i class="fa fa-telegram telegram">Altivoli Gruppo Telegram </i></a>
    </div>  
 
    <div class="row mt-4 text-center">
      <a href="tel:+39 3281768548"><i class="fa fa-phone telephone"> Franco Terenzi (Presidente)</i></a>
    </div>

    <div class="row m-0 p-0 text-center">
      <p>+39 3281768548</p>
    </div>
            
    <div class="row mt-2 text-center">
      <a href="https://telegram.me/flyingmp"><i class="fa fa-telegram telegram">Michele Palma (Sviluppatore)</i></a>
    </div>   
        
    <div class="row mt-4 text-center">
      <a href="mailto:info@altivoli.it"><i class="fa fa-envelope email">info@altivoli.it</i></a>
    </div>  
  </div>
</template>

<script>
export default {
  name: 'AboutUsPage',
  props: {
    title: String
  }
}
</script>

<style scoped>

</style>
